import styled, { css } from 'styled-components';
import { always } from 'ramda';

import { renderWhenTrue } from '@utils/rendering';
import { media } from '@theme/media';
import { Button } from '@components/form';

export const Label = styled.span`
  position: relative;
  white-space: nowrap;
`;

const getCheckMarkStyles = renderWhenTrue(
  always(css`
    ${Label} {
      padding-left: 20px;
    }

    ${Label}:before {
      content: '\\2713';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  `)
);

const getRevertedColorsStyles = (textColor: string, backgroundColor: string) =>
  renderWhenTrue(
    always(css`
      background-color: ${textColor};
      color: ${backgroundColor};

      &:hover {
        background-color: ${backgroundColor};
        color: ${textColor};
      }
    `)
  );

export const WaitListButton = styled(Button)<{
  active?: boolean;
  reverted?: boolean;
  centered?: boolean;
  textColor: string;
  backgroundColor: string;
  price?:boolean;
}>`
  background-color: transparent;
  display: flex;
  align-items: center;
  position: relative;
  color: ${({ textColor }) => textColor};
  border-color: ${({ textColor }) => textColor};
  white-space: nowrap;

  &:hover {
    background-color: ${({ textColor }) => textColor};
    color: ${({ backgroundColor }) => backgroundColor};
  }

  ${({ active }) => getCheckMarkStyles(active)};
  ${({ reverted, textColor, backgroundColor }) => getRevertedColorsStyles(textColor, backgroundColor)(reverted)};
  justify-content: ${({ price }) => (!price ? 'center' : 'space-between')};;
  flex-direction: row-reverse;

  ${media.tablet} {
    flex-direction: row;
  }
`;

interface CopyProps {
  isArtworkWithMargin: boolean;
}

export const DesktopCopy = styled.span<CopyProps>`
  display: none;

  ${media.tablet} {
    display: ${({ isArtworkWithMargin }) => (isArtworkWithMargin ? 'none' : 'inline')};
    padding-top: 1px;
  }

  ${media.desktop} {
    display: inline;
  }
`;

export const MobileCopy = styled.span<CopyProps>`
  ${media.tablet} {
    display: ${({ isArtworkWithMargin }) => (isArtworkWithMargin ? 'inline' : 'none')};
  }

  ${media.desktop} {
    display: none;
  }
`;

export const Price = styled.span`
  margin-right: 12px;

  ${media.tablet} {
    margin-right: 0;
  }
`;
